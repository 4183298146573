// Compiled file located in assets/algolia.js (cf: config/script.js)

const asc = Translator.translate('price_asc');
const desc = Translator.translate('price_desc');
const sessionStorageCache = instantsearch.createInfiniteHitsSessionStorageCache();
const instantSearchRouter = instantsearch.routers.history();
const { highlight } = instantsearch;
const { rangeSlider } = instantsearch.widgets;
const { infiniteHits } = instantsearch.widgets;
// or directly use instantsearch.widgets.infiniteHits()


const client = algoliasearch(`${appId}`, `${token}`);
const index_name = `${index_name_algolia}`;
const price_asc_index_name = `${algolia_price_asc_index_name}`;
const price_desc_index_name = `${algolia_price_desc_index_name}`;
const algolia_suggestions_index_name = `${suggestions_index_name}`;


const index = client.initIndex(index_name);
const { sortBy } = instantsearch.widgets;
const { connectSortBy } = instantsearch.connectors;
const { stats } = instantsearch.widgets;


const searchClient = {
    ...client,
    search(requests) {
        if (requests.every(({ params }) => !params.query || (params.query && params.query.replace(/\s/g, '').length < 3))) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }
        return client.search(requests);
    },
};

const instantSearchClient = {
    ...client,
    search(requests) {
        if (requests.every(({ params }) => !params.query || (params.query && params.query.replace(/\s/g, '').length < 3))) {
            return Promise.resolve({
                results: requests.map(() => ({
                    hits: [],
                    nbHits: 0,
                    nbPages: 0,
                    page: 0,
                    processingTimeMS: 0,
                    hitsPerPage: 0,
                    exhaustiveNbHits: false,
                    query: '',
                    params: '',
                })),
            });
        }
        return client.search(requests);
    },
};

const { autocomplete } = window[
    '@algolia/autocomplete-js'
];

let isFromScroll = false;
let original_search_term = '';
let scrollPosition = false;

const container = document.querySelector('#results-wrapper');
const alg_app_container = document.querySelector('.ais-InstantSearch');

const search = instantsearch({
    indexName: index_name,
    searchClient: instantSearchClient,
    minLength: 3,
    // gestion erreur ApiError: invalid numeric attribute(price), attribute not specified in numericAttributesForFiltering setting
    // numericAttributesForFiltering: ['price'],
    searchFunction(helper) {

        const container = document.querySelector('#results-wrapper');

        const alg_app_container = document.querySelector('.ais-InstantSearch');
        var helper_state_query = helper.state.query;

        // Display or hide container depending on query
        if (helper_state_query.replace(/\s/g, '').length < 3) {

            container.style.display = 'none';
            document.querySelector('.algolia_footer').style.display = 'none';

        } else {

            if(algolia_mobile) {
                // Check if an item is already in wishlist
                getWishlistProductsRay();
            } else {
                getWishlistProducts();
                checkProductInWishlist();
            }
            // Display results
            setTimeout(function () {
                container.style.display = ''; // Remove display none from elem
                document.querySelector('.algolia_footer').style.display = ''; // Remove display none from custom Algolia footer

                // Update wishlist header and the wishlist lightbox
                if ($('#show_top_wish .product-link').length) {
                    addToWishlistUpdateHeaderCount();
                }
                //Update swiper state
                swiperStateUpdate();
            }, 100);

            // Remove loader on loadMore btn
            if ($('.ais-InfiniteHits-loadMore').hasClass('loading')) {
                $('.ais-InfiniteHits-loadMore').removeClass('loading');
            }
        }

        if (original_search_term !== helper.state.query) {
            helper.clearRefinements().search();
            original_search_term = helper.state.query;
        }

        /* If the query states that there's no result, hide left panel **********/
        if (helper.state.query === 'noResultTrigger') {
            $('.left-panel').hide();
            $('.ais-InstantSearch').addClass('mobile-display');

            $('#no_res_push_bloc_list,#no_res_best_sales_list').show();
        } else {
            // Hide gondole title
            $('.no_results_wrapper, .gondoleTitle').hide();
            $('.ais-InstantSearch').removeClass('mobile-display');
        }

        var query = search.helper.state.query;

        if (query.replace(/\s/g, '').length > 2 && !query.startsWith(" ")) {
            helper.search();
        }
    },
    routing: {
        stateMapping: instantsearch.stateMappings.singleIndex(index_name),
        router: instantsearch.routers.history({
            parseURL({ qsModule, location }) {
                const { query = '', page, brands = [] } = qsModule.parse(
                    location.search.slice(1)
                );
                // `qs` does not return an array when there's a single value.
                const allBrands = Array.isArray(brands)
                    ? brands
                    : [brands].filter(Boolean);

                if (isStringCorrupted(query)) {
                    return;
                } else {
                    return {
                        query: decodeURIComponent(removeHTMLAndScripts(query)),
                        page,
                        brands: allBrands.map(decodeURIComponent),
                        // category
                    };
                }
            },
            createURL({ qsModule, routeState, location }) {

                const urlObj = new URL(location);
                const params = new URLSearchParams(location.search.slice(1));

                // current search params
                const indexState = routeState[index_name] || {};
                const { origin, pathname, hash, search } = location;
                // grab current query string and convert to object
                const queryParameters = qsModule.parse(search.slice(1)) || {};

                // if there is an active search
                if (Object.keys(indexState).length) {
                    // merge the search params with the current query params
                    Object.assign(queryParameters, routeState);
                } else {
                    // remove the search params
                    params.delete('query');
                }

                // Overriding the query parameter with the one from the route state
                if (routeState.query) {
                    params.set('query', encodeURIComponent(routeState.query));
                } else {
                    if (params.has('page')) {
                        params.delete('page');
                    }
                }

                if (routeState.page !== undefined && routeState.page !== 1) {
                    params.set('page', routeState.page);
                }

                if (routeState.brands) {
                    params.set('brands', routeState.brands.map(encodeURIComponent));
                }

                // Convert URLSearchParams to a regular JavaScript object
                let queryStringParameters = {};
                params.forEach((value, key) => {
                    queryStringParameters[key] = value;
                });

                // Ensure querystring ordered : query, page ...
                queryStringParameters = preferredOrder(queryStringParameters, ['query', 'page']);

                const queryString = qsModule.stringify(queryStringParameters, {
                    addQueryPrefix: true,
                    arrayFormat: 'repeat'
                });

                // Build the new URL with the filtered parameters
                const newUrl = `${urlObj.origin}${urlObj.pathname}${queryString}`;

                return newUrl;
            },
        })
    },
    searchParameters: {
        clickAnalytics: true
    }
});

var tgSwiper;
let lastRenderArgs;

search.use(
    instantsearch.middlewares.createInsightsMiddleware({
        insightsClient: aa,
    })
);

// Execute once
search.once('render', () => {
    scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
        setTimeout(() => {
            window.scrollTo(0, parseInt(scrollPosition, 10));
            if (algolia_mobile) {
                $(".ais-InstantSearch").animate({
                    scrollTop: scrollPosition
                }, 2000);

            } else {
                window.scrollTo(0, parseInt(scrollPosition, 10));
            }
            sessionStorage.removeItem('scrollPosition');
        }, 300);
    }
});

// Do something on render
search.on('render', () => {

    if ($('.searchItemSlider').length) {
        initItemSlider(false);
    }

    $(window).on('scroll', function() {
        initItemSlider(false);
    });

    // Add class displayed and display results-wrapper if something more than 3 caracs are typed
    if (!document.querySelector('.ais-InstantSearch').classList.contains('displayed') && (document.querySelector('.aa-Input').value.trim().length >= 3)) {
        setTimeout(function () {
            document.querySelector('body').classList.add('algolia_on');
            document.querySelector('.ais-InstantSearch').classList.add('displayed'); // displayed on algolia container first
            setTimeout(function() {document.querySelector('body').classList.add('algolia_displayed')}, 100); // algolia_displayed on body after so we dont see any blink
            document.querySelector('#results-wrapper').style.display = '';

            if ((search.helper.lastResults != null) && (search.helper.lastResults.query != search.helper.state.query)) {
                if (!scrollPosition) {
                    setBackToTop();
                }
            }


        }, 200)
    } else {
        // Checking if there's a NEW search term on render
        if ((search.helper.lastResults != null) && (search.helper.lastResults.query != search.helper.state.query)) {
            if (!scrollPosition) {
                setBackToTop();
            }

        }
    }

    // On desktop we're using lazyload.js --> lazyload-new.js from _app repository, use lazyloadImage function when rendering new content
    // Trigger lazyload function when rendering search
    if (!algolia_mobile && (search.helper.state.query != '')) {
        if (typeof lazyloadImage === 'function') {
            lazyloadImage();
        }
    }

});

const virtualRefinementList = instantsearch.connectors.connectRefinementList(
    () => null
);
const virtualSearchBox = instantsearch.connectors.connectSearchBox(() => {});


/* Custom "trier par" element ******************/
const renderSortBy = (renderOptions, isFirstRender) => {
    const {
        options,
        currentRefinement,
        refine,
        widgetParams,
        // `canRefine` is only available from v4.45.0
        // Use `hasNoResults` in earlier minor versions.
        canRefine,
    } = renderOptions;

    let filter_sortby = '';
    // Clean filter_trier if it exists
    if (!document.querySelector('.ais-InstantSearch #filter_trier')) {
        // Create filter_trier div
        filter_sortby = document.createElement('div');
        filter_sortby.id = 'filter_trier';

        document.querySelector(widgetParams.container).appendChild(filter_sortby);
    }

    // If renderOptions has a matching refinement (asc or desc), set it as the currentRefinementLabel in order to use it in the render (sortby.innerHTML)
    let currentRefinementLabel = '';
    if (!isFirstRender) {
        // If currentRefinement is either asc or desc, set currentRefinementLabel to the matching refinement (to use in the Translator.translate() function)
        if (renderOptions.currentRefinement === price_asc_index_name) {
            currentRefinementLabel = 'croissant';
            $('[name="choice_tri"][value="' + price_asc_index_name + '"]').addClass('checked');
        } else if (renderOptions.currentRefinement === price_desc_index_name) {
            currentRefinementLabel = 'decroissant';

            $('[name="choice_tri"][value="' + price_desc_index_name + '"]').addClass('checked');
        }
    }



    // Set sortby as the filter_trier div in the widget container
    const sortby = document.querySelector(widgetParams.container).querySelector('.ais-InstantSearch #filter_trier');
    let sortby_html;

    if (algolia_mobile) {
        sortby_html = `
            ${options
                .map(
                    option => `
                <li class="ais-RefinementList-item">
                    <div class="form_itm check ${(renderOptions.currentRefinement == option.value) ? 'checked' : ''}">
                        <label class="filter_label" data-label="${option.label}">${option.label}</label>
                        <input type="hidden" name="choice_tri" value="${option.value}">
                    </div>
                </li>
                `
                )
                .join('')}
        `;
    } else {
        sortby_html = `
            ${Translator.translate('trier_par')} : <span id="selectedSortFilter">${(currentRefinementLabel !== '') ? Translator.translate('texte_prix_' + currentRefinementLabel) : Translator.translate('pertinence')}</span><div id="pic"></div>
            <ul class="selected_options">
                <li class="form_itm check ${(renderOptions.currentRefinement == index_name) ? 'checked' : ''}">
                    <span class="filter_option" data-label="${Translator.translate('pertinence')}">${Translator.translate('pertinence')}</span>
                    <input type="hidden" name="choice_tri" value="${index_name}">
                </li>
            ${options
                .map(
                    option => `
                <li class="form_itm check ${(renderOptions.currentRefinement == option.value) ? 'checked' : ''}">
                    <span class="filter_option" data-label="${option.label}">${option.label}</span>
                    <input type="hidden" name="choice_tri" value="${option.value}">
                </li>
                `
                )
                .join('')}
            </ul>
        `;
    }

    sortby.innerHTML = sortby_html;


    /* On trier_par itm click, set the selectedSortFilter to the clicked option and refine the search with the selected option */
    $('#sort-by .form_itm').on('click', function () {
        if (!algolia_mobile) {
            $(this).closest('#filter_trier').find('#selectedSortFilter').html(`${$(this).find('.filter_option').attr('data-label')}`);
            // Refine with correct index name
            refine($(this).find('[name="choice_tri"]').val());
        } else {
            /* If clicked element is already checked, remove check and refine blank */
            if ($(this).hasClass('checked')) {
                $(this).removeClass('checked');
                // Reset refinement
                refine(index_name);
            } else {
                /* If clicked element is not checked, remove check on other elements and refine with correct value */
                if ($('#sort-by .form_itm.checked').length) {
                    $('#sort-by .form_itm.checked').removeClass('checked');
                    // Reset refinement
                    refine(index_name);
                }
                // Refine with correct index name
                refine($(this).find('[name="choice_tri"]').val());
            }
        }
    });
};
const customSortBy = connectSortBy(
    renderSortBy
);


search.addWidgets([
    virtualSearchBox({}),
    customSortBy({
        container: '#sort-by',
        items: [
            { value: price_asc_index_name, label: asc },
            { value: price_desc_index_name, label: desc },
        ],
    }),
    // ROLLOVER FILTER BLOC ************************************************************************************************************ /
    instantsearch.widgets.dynamicWidgets({
        facets: ['*'],
        container: '.ais-InstantSearch #filter_sticky_search .filters_container_wrapper',
        // Fallback Widget for every other facets to display that are not independently declared
        fallbackWidget: ({ container, attribute }) =>
            instantsearch.widgets.panel({
                container,
                cssClasses: {
                    root: 'filters_dropdown_bloc',
                    header: 'toggleFilter',
                    body: 'selected_options'
                },
                templates: {
                    header: `<span id="${attrNicename(attribute)}" class="trigger_dropdown" onclick="toggleDropdown(event)">${transformAttribute(attribute)}</span>`,
                },
                hidden(options) {
                    return options.items.length === 0;
                }
            })(instantsearch.widgets.refinementList)({
                container,
                attribute: attribute,
                templates: {
                    item: `
                    <a href="{{url}}" class="form_itm {{#isRefined}}checked{{/isRefined}}" style="{{#isRefined}}font-weight: bold{{/isRefined}}">
                        <label class="filter_label">{{label}} <span class="available_count">{{count}}</span></label>
                    </a>
                    `,
                }
            }
            ),
        widgets: [
            container =>
                instantsearch.widgets.panel({
                    container,
                    cssClasses: {
                        root: 'filters_dropdown_bloc',
                        header: 'toggleFilter',
                        body: 'selected_options colorOptions'
                    },
                    templates: {
                        header: `<span id="${attrNicename(Translator.translate('algolia_nicename_color'))}" class="trigger_dropdown" onclick="toggleDropdown(event)">${Translator.translate('algolia_nicename_color')}</span>`

                    },
                })(instantsearch.widgets.refinementList)({
                    container,
                    attribute: 'color_name_hex',
                    transformItems: function (items) {
                        return items.map(function (item) {
                            item.color_name = item.label.split("||")[0].toLowerCase();
                            item.color_hex = item.label.split("||")[1].toLowerCase();
                            return item;
                        });
                    },
                    templates: {
                        item: `
                            <a href="{{url}}" class="form_itm color {{#isRefined}}checked{{/isRefined}}" data-color="{{color_name}}"  style="background-color: {{color_hex}}">
                                <label class="color_picto color_bullet filter_label">{{color_name}} <span class="available_count">{{count}}</span></label>
                            </a>
                        `,
                    }
                }),
        ],
    }),
    instantsearch.widgets.clearRefinements({
        container: '#clear-refinements_sticky',
        templates: {
            resetLabel: algolia_mobile ? Translator.translate('algolia_mobile_reset_filters') : Translator.translate('reset_sticky_filters'),
        },
    }),
    // ********************************************************************************************************************************* /


    // TOP LINE FILTER BLOC ************************************************************************************************************ /
    instantsearch.widgets.dynamicWidgets({
        facets: ['*'],
        container: '#holder_filters_sections #trigger_filtre',
        // Fallback Widget for every other facets to display that are not independently declared
        fallbackWidget: ({ container, attribute }) =>
            instantsearch.widgets.panel({
                container,
                templates: {
                    header(options, {html}) {
                        if (options.results) {
                            return `<span id="${attrNicename(options.widgetParams.attribute)}" class="filtre_elem ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? 'toggled' : ''}" onclick="displayFilters('${attrNicename(options.widgetParams.attribute)}')">${transformAttribute(options.widgetParams.attribute)} ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? ('<span class="count_filters">' + options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length + '</span></span>') : ''}`;
                        }
                    }
                },
                hidden(options) {
                    return options.items.length === 0;
                }
            })(instantsearch.widgets.refinementList)({
                container,
                attribute: attribute,
                templates: {
                    item: `
                    <a href="{{url}}" style="{{#isRefined}}font-weight: bold{{/isRefined}}">
                        <span>{{label}}</span>
                    </a>
                    `,
                }
            }
            ),
        widgets: [
            container =>
                instantsearch.widgets.panel({
                    container,
                    templates: {
                        header(options, {html}) {

                            return `<span id="${attrNicename(Translator.translate('algolia_nicename_color'))}" class="filtre_elem ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? 'toggled' : ''}" onclick="displayFilters('${attrNicename(Translator.translate('algolia_nicename_color'))}')">${Translator.translate('algolia_nicename_color')} ${(options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute] && options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length > 0) ? ('<span class="count_filters">' + options.state.disjunctiveFacetsRefinements[options.widgetParams.attribute].length + '</span></span>') : ''}`;
                        }
                    },
                })(instantsearch.widgets.refinementList)({
                    container,
                    attribute: 'color_name_hex',
                    templates: {
                        item: `
                            <a href="{{url}}" style="{{#isRefined}}font-weight: bold{{/isRefined}}">
                                <label">{{label}}</label>
                            </a>
                        `,
                    }
                }),
        ],
    }),

    instantsearch.widgets
        .index({ indexName: algolia_suggestions_index_name })
        .addWidgets([
            instantsearch.widgets.hits({
                container: document.querySelector('#query_suggestions_list'),
                cssClasses: {
                    root: 'swiper-container',
                    list: 'list_suggestions swiper-wrapper',
                    item: `swiper-slide suggestion`,
                },
                transformItems(items, { results }) {
                    return items.map(item => ({
                        ...item,
                        query: results.query,
                    }));
                },
                templates: {
                    item(hit) {

                        const query = hit.objectID;
                        return `<a
                    href='javascript:void(0);'
                    onClick="initAutcompleteFromSuggestions('${hit.objectID}');return false;"
                    class="res-suggestion-item aa-ItemLink ${query === original_search_term.toLowerCase() ? 'selected' : ''}">${hit.objectID}
                    </a>`;
                    },
                    empty: ''
                },
            }),
            instantsearch.widgets.configure({
                hitsPerPage: 10,
            }),
        ]),
    instantsearch.widgets.configure({
        clickAnalytics: true,
        hitsPerPage: 60, // standard response hits per page

    }),
    instantsearch.widgets.infiniteHits({
        container: '#hits',
        transformItems: function (items) {
            return items.map(function (item) {

                item.price_unit = getPartPrice(item.price, 'units');
                item.price_cent = getPartPrice(item.price, 'cents');
                item.no_cents_class = (item.price_cent == '00') ? 'no_cents' : '';

                item.discounted_price_unit = getPartPrice(item.discounted_price, 'units');
                item.discounted_price_cent = getPartPrice(item.discounted_price, 'cents');
                item.no_cents_class_discounted = (item.discounted_price_cent == '00') ? 'no_cents' : '';

                item.price_per_produit_unit = getPartPrice(item.price_per_produit, 'units');
                item.price_per_produit_cent = getPartPrice(item.price_per_produit, 'cents');
                item.no_cents_class_per_produit = (item.price_per_produit_cent == '00') ? 'no_cents' : '';

                item.discounted_price_per_produit_unit = getPartPrice(item.discounted_price_per_produit, 'units');
                item.discounted_price_per_produit_cent = getPartPrice(item.discounted_price_per_produit, 'cents');
                item.no_cents_class_discounted_per_produit = (item.discounted_price_per_produit_cent == '00') ? 'no_cents' : '';

                return item;
            });
        },
        templates: {
            item(hit, { html, components }) {
                return itemTemplate(hit, hit.rate, false);
            },
            showPreviousText: Translator.translate('algolia_showless'),
            showMoreText: Translator.translate('algolia_showmore'),

            empty(results, { html }) {
                // summary: on empty results, do not trigger noresults unless query is greater than or equal to 3 characters.
                var primary_results = results.query;
                if (primary_results.trim().length >= 3 && results.nbHits == 0 && !primary_results.endsWith(" ")) {

                    // set query to rules
                    no_res_push_search.helper.setQuery('noResultTrigger');

                    // attach noreults hits to custom design no_res_push_search
                    no_res_push_search.addWidget(no_res_push_searchHits);
                }
                document.getElementById("algolia_visited_articles").style.cssText = "display: block;";
                document.getElementById("noresult").style.cssText = "display: flex;";

                let params_swiper,
                    params_visited_swiper;
                if (algolia_mobile) {
                    // Swiper Bestseller
                    params_swiper = {
                        roundLengths: true,
                        setWrapperSize: true,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 10,
                        navigation: {
                            nextEl: '.container_inject_gondole .no_res_next',
                            prevEl: '.container_inject_gondole .no_res_prev',
                        },
                        pagination: {
                            el: ".container_inject_gondole .no_res_pagination",
                            type: "progressbar",
                        },
                    };
                    // Swiper visited articles
                    params_visited_swiper = {
                        roundLengths: true,
                        setWrapperSize: true,
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 10,
                        navigation: {
                            nextEl: '#algolia_visited_articles .visited-next',
                            prevEl: '#algolia_visited_articles .visited-prev',
                        },
                        pagination: {
                            el: "#algolia_visited_articles .swiper_pagination",
                            type: "progressbar",
                        },
                    };

                } else {
                    // Swiper Bestseller
                    params_swiper = {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 13,
                        roundLengths: true,
                        setWrapperSize: true,
                        breakpoints: {
                            1300: {
                                slidesPerView: 5,
                                slidesPerGroup: 5,
                            },
                        },
                        navigation: {
                            nextEl: '.container_inject_gondole .no_res_next',
                            prevEl: '.container_inject_gondole .no_res_prev',
                        },
                        pagination: {
                            el: ".container_inject_gondole .no_res_pagination",
                            type: "progressbar",
                        },
                    }
                    // Swiper visited articles
                    params_visited_swiper = {
                        slidesPerView: 4,
                        slidesPerGroup: 4,
                        spaceBetween: 13,
                        roundLengths: true,
                        setWrapperSize: true,
                        breakpoints: {
                            1300: {
                                slidesPerView: 5,
                                slidesPerGroup: 5,
                            },
                        },
                        navigation: {
                            nextEl: '#algolia_visited_articles .rightarrow',
                            prevEl: '#algolia_visited_articles .leftarrow',
                        },
                        pagination: {
                            el: "#algolia_visited_articles .swiper-pagination",
                            type: "progressbar",
                        },
                    }
                }

                // Initialize gondole from Algolia BO parsed in DOM
                setTimeout(function () {
                    initializeSwiper('.inject_gondole .swiper-container', params_swiper);
                    initializeSwiper('#algolia_visited_articles #productVisitedSwiper', params_visited_swiper);
                }, 500);
                document.querySelector(".bloc_suggestions_container").style.cssText = "display: none;";
                $('.left-panel').hide();
                return '';
            },
        },
        cache: sessionStorageCache,
    }),
    instantsearch.widgets.analytics({
        pushFunction() {
            // push index Name / userToken to DataLayer
            window.dataLayer = window.dataLayer || [];

            if (index_name) {
                window.dataLayer.push({
                    algoliaIndexName: index_name
                });
            }

            // push unique UserToken to dayalayer

            function getCookie(name) {
                var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
                return v ? v[2] : null;
            }

            // if user connected

            let connectedUserToken = '';
            if (dataLayer !== undefined && dataLayer !== null && dataLayer[0] !== undefined && dataLayer[0] !== null && dataLayer[0].user !== undefined && dataLayer[0].user !== null) {
                connectedUserToken = dataLayer[0].user.md5;
            }

            let token = getCookie('_ALGOLIA');
            if (connectedUserToken) {
                aa('setUserToken', connectedUserToken);

                window.dataLayer.push({
                    algoliaUserToken: connectedUserToken
                });
            } else if (connectedUserToken === '' && token !== '' && token !== undefined) {
                aa('setUserToken', token);
                window.dataLayer.push({
                    algoliaUserToken: token
                });
            }
        },
        pushInitialSearch: false,
        pushPagination: true
    }),
    stats({
        container: '#stats .holder_recap',
        templates: {
            text(data, { html }) {
                let count = '';
                // Handle suggestions swiper
                setTimeout(function () {
                    let params_swiper;
                    if (algolia_mobile) {
                        params_swiper = {
                            init: $('#query_suggestions_list .swiper-slide').length > 2 ? true : false,
                            roundLengths: true,
                            setWrapperSize: true,
                            slidesPerView: 'auto',
                            spaceBetween: 10,
                        };
                    } else {
                        params_swiper = {
                            init: $('#query_suggestions_list .swiper-slide').length > 2 ? true : false,
                            roundLengths: true,
                            setWrapperSize: true,
                            slidesPerView: 'auto',
                            spaceBetween: 20,
                            navigation: {
                                prevEl: '.suggestions_prev',
                                nextEl: '.suggestions_next',
                            },
                            observer: true
                        };
                    }
                    initializeSwiper('#query_suggestions_list .swiper-container', params_swiper);

                    if (params_swiper.init == false) {
                        if (!$('.bloc_suggestions_container').hasClass('no_swiper')) {
                            $('.bloc_suggestions_container').addClass('no_swiper');
                        }
                    } else {
                        if ($('.bloc_suggestions_container').hasClass('no_swiper')) {
                            $('.bloc_suggestions_container').removeClass('no_swiper');
                        }
                    }
                }, 500);

                // HIDE AND DISPLAY CORRECT CONTENT
                if (data.hasManyResults) {
                    count += `${data.nbHits + ' ' + Translator.translate('algolia_results_products')}`;
                    document.getElementById("noresult").style.cssText = "display: none;";
                    document.getElementById("algolia_visited_articles").style.cssText = "display: none;";
                    document.querySelector(".bloc_suggestions_container").style.cssText = "display: block;";

                    if (document.querySelector("#stats").classList.contains('no_results')) {
                        document.querySelector("#stats").classList.remove('no_results');
                    }

                    $('.left-panel').show();
                } else if (data.hasOneResult) {
                    document.getElementById("noresult").style.cssText = "display: none;";
                    document.getElementById("algolia_visited_articles").style.cssText = "display: none;";

                    document.querySelector(".bloc_suggestions_container").style.cssText = "display: block;";

                    if (document.querySelector("#stats").classList.contains('no_results')) {
                        document.querySelector("#stats").classList.remove('no_results');
                    }

                    count += `1 ${Translator.translate('algolia_results_product')}`;    // translates below comment
                } else {
                    count += `${data.nbHits + ' ' + Translator.translate('algolia_results_product')}`;
                    document.querySelector(".bloc_suggestions_container").style.cssText = "display: none;";

                    document.querySelector("#stats").classList.add('no_results');

                    let final_txt = `<p> <span class="result_query">${data.query}</span> <span class="result_count">${count}</span></p>` + '<div class="no_result_txt">' + Translator.translate((algolia_mobile ? 'no_response_search_mobile' : 'no_response_search')) + '</div>' + '<div class="no_result_txt_advice">' + Translator.translate((algolia_mobile ? 'no_response_search_advice_mobile' : 'no_response_search_advice')) + '</div>';
                    return final_txt;
                }

                return html`<span class="result_query search_term">${data.query}</span> <span class="result_count nbr_result">${count}</span>`; // constant & style to be added

            },
        },
    })
]);

// handle redirects rules set in algolia back office rules
search.addWidgets([
    instantsearch.widgets.queryRuleCustomData({
        container: '#queryRuleCustomData',
        templates: {
            default: '',
        },
        transformItems(items) {
            const match = items.find(data => Boolean(data.redirect));
            if (match && match.redirect) {
                window.location.href = match.redirect.url;
            }
            return [];
        },
    })
]);


search.addWidget(
    instantsearch.widgets.rangeSlider({
        container: '#range-slider',
        attribute: 'price',
        pips: false,
        tooltips: {
            format: function (rawValue) {
                return `${rawValue + decode(currencySymbol)}`;
            }
        },
        precision: 2
    })
);

search.start();

// no results page bloc
const no_res_push_search = instantsearch({
    searchClient: searchClient,
    indexName: index_name,

    // force and trigger only on no results rule query noResultTrigger
    searchFunction(helper) {
        if (helper.state.query === 'noResultTrigger') {
            helper.search();
        }
    },
});

const no_res_push_searchHits = instantsearch.widgets.hits({
    container: document.querySelector('.inject_gondole'),
    hitsPerPage: 6,
    cssClasses: {
        root: 'swiper-container',
        list: 'swiper-wrapper',
        item: 'swiper-slide'
    },
    transformItems: function (items) {
        if (items.length == 0) {
            document.querySelector('.noresult_gondole_title').classList.add('no_swiper');
            document.querySelector('.inject_gondole').classList.add('no_swiper');
            container.classList.add('no_swiper');
        } else {
            if (document.querySelector('.noresult_gondole_title').classList.contains('no_swiper')) {
                document.querySelector('.noresult_gondole_title').classList.remove('no_swiper');
                container.classList.remove('no_swiper');
            }
        }

        return items.map(function (item) {

            item.price_unit = getPartPrice(item.price, 'units');
            item.price_cent = getPartPrice(item.price, 'cents');
            item.no_cents_class = (item.price_cent == '00') ? 'no_cents' : '';

            item.discounted_price_unit = getPartPrice(item.discounted_price, 'units');
            item.discounted_price_cent = getPartPrice(item.discounted_price, 'cents');
            item.no_cents_class_discounted = (item.discounted_price_cent == '00') ? 'no_cents' : '';

            item.price_per_produit_unit = getPartPrice(item.price_per_produit, 'units');
            item.price_per_produit_cent = getPartPrice(item.price_per_produit, 'cents');
            item.no_cents_class_per_produit = (item.price_per_produit_cent == '00') ? 'no_cents' : '';

            item.discounted_price_per_produit_unit = getPartPrice(item.discounted_price_per_produit, 'units');
            item.discounted_price_per_produit_cent = getPartPrice(item.discounted_price_per_produit, 'cents');
            item.no_cents_class_discounted_per_produit = (item.discounted_price_per_produit_cent == '00') ? 'no_cents' : '';

            return item;
        });
    },
    templates: {
        item(hit, { html, components }) {
            return itemTemplate(hit, hit.rate, false);
        },
    },

});

no_res_push_search.start();


// Set the InstantSearch index UI state from external events.
function setInstantSearchUiState(indexUiState) {
    search.setUiState(uiState => ({
        ...uiState,
        [index_name]: {
            ...uiState[index_name],
            // We reset the page when the search state changes.
            ...indexUiState,
        },
    }));
}
// Return the InstantSearch index UI state.
function getInstantSearchUiState() {
    const uiState = instantSearchRouter.read();
    return uiState;
}

// Algolia back to top btn
function backToTopAlgolia() {
    if (algolia_mobile) {
        var scrollTop = $('.ais-InstantSearch').scrollTop(),
            speed = Math.min(scrollTop * 0.8, window.innerHeight);

        $(".ais-InstantSearch").animate({ scrollTop: 0 }, (speed * 2));
    } else {
        $('html, body').animate({ scrollTop: 0 }, 500, 'linear');
    }
}

// Used to display mobile filters
function toggleAlgoFilters(target = null) {
    var menu = document.getElementById('filter_sticky_search');
    var shad = document.getElementById('algolia_shade');

     // If search input is active, hide it
    if ($('.ais-container').hasClass('actif')) {
        repositionAlgoElems("hide");
    }

    if (menu) {

        if (menu.classList.toggle('open') && shad) {
            document.querySelector('.ais-InstantSearch').classList.add('with_filters');
            shad.classList.add('displayed');
            document.body.classList.add("noscroll");
            shad.classList.add('forMenu');
            $('#algolia_shade').show();

        } else if (shad) {
            document.querySelector('.ais-InstantSearch').classList.remove('with_filters');
            shad.classList.remove('displayed');
            shad.classList.remove('forMenu');
            shad.classList.remove('forFilters');
            $('#algolia_shade').hide();
            document.body.classList.remove("noscroll");
        }
    } else if (shad) {

        shad.classList.toggle('visible');
        $('#algolia_shade').toggle();

    }

    if (target !== null && !$(target).parent('.ais-Panel').hasClass('body_visible')) {
        if ($('.ais-Panel.body_visible').length) {
            $('.ais-Panel.body_visible .ais-Panel-body').slideUp(200);
            $('.ais-Panel.body_visible').removeClass('body_visible');
        }
        $(target).parents('.ais-Panel').addClass('body_visible');
        $(target).parents('.ais-Panel').find('.selected_options').slideDown(100);
    }
}

// Build URLs that InstantSearch understands.
function getInstantSearchUrl({ query }) {
    return search.createURL({ [index_name]: query }) + `?query=` + encodeURIComponent(`${query}`); // add variable to manage url dynamically
}
const searchPageState = getInstantSearchUiState();

function getItemUrl({ query }) {
    return getInstantSearchUrl({ query });
}
// Detect when an event is modified with a special key to let the browser
// trigger its default behavior.
function isModifierEvent(event) {
    const isMiddleClick = event.button === 1;

    return (
        isMiddleClick ||
        event.altKey ||
        event.ctrlKey ||
        event.metaKey ||
        event.shiftKey
    );
}

function onSelect({ setIsOpen, setQuery, event, query, category }) {
    // You want to trigger the default browser behavior if the event is modified.
    if (isModifierEvent(event)) {
        return;
    }

    setQuery(query);
    setIsOpen(false);
    setInstantSearchUiState({
        query,
    });

    // When rendering after clicking on a suggestion in list
    if ((document.activeElement == document.getElementById('autocomplete-0-input')) && document.getElementById('algolia_shade').classList.contains('displayed')) {
        triggerAlgoInputShad('remove');
    }
}

function createItemWrapperTemplate({ children, query, html, hitsCount }) {

    const uiState = { query };
    let customHit = hitsCount;


    return html`
    <a class="aa-ItemLink" href="${getInstantSearchUrl(uiState)}" onClick="${(event) => {
            if (!isModifierEvent(event)) {
                // Bypass the original link behavior if there's no event modifier
                // to set the InstantSearch UI state without reloading the page.
                event.preventDefault();
            }
        }}">
        ${children}
        ${(customHit !== '') ? (html`<span class="aa-ItemLink_nbSug">${customHit}</span>`) : ''}

    </a>`;
}

let timerId;
let timeout = 250;

let skipInstantSearchUiStateUpdate = false;
const { setQuery } = autocomplete({
    container: '#autocomplete',
    placeholder: Translator.translate('site_search_placeholder'),
    detachedMediaQuery: 'none',
    openOnFocus: false,
    minLength: 3,
    initialState: {
        query: searchPageState.query || '',
    },
    onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query });

    },
    onReset() {
        if (algolia_mobile) {
            resetAlgolia();
        }

        clearTimeout(timerId);

        // Since queries state changes are operated with a timeout, we need to keep logic on reset
        timerId = setTimeout(() => {
            setInstantSearchUiState({ query: '' })
            $('body').removeClass('algolia_displayed');
            setTimeout(function () { $('.ais-InstantSearch').removeClass('displayed') }, 100);
        }, timeout);

    },
    onStateChange({ prevState, state }) {
        // If the query has changed, update the InstantSearch UI state.
        if (prevState.query !== state.query) {

            // On first query, no timeout
            if (prevState.query.length < 3 && state.query.length >= 3) {
                timeout = 0;
            }

            if (!skipInstantSearchUiStateUpdate && prevState.query !== state.query && !state.query.endsWith(" ")) {
                // setInstantSearchUiState({ query: state.query });
                clearTimeout(timerId);
                if (isStringCorrupted(state.query)) {
                    // In case it is, return
                    return;
                } else {
                    // If not, trigger query
                    timerId = setTimeout(() => {
                        setInstantSearchUiState({ query: removeHTMLAndScripts(state.query) })

                        // On state change, if query is less than 3 characters, hide algolia
                        if (state.query.length < 3) {
                            $('body').removeClass('algolia_displayed');
                            setTimeout(function () { $('.ais-InstantSearch').removeClass('displayed'), 100});
                        }
                    }, timeout);
                }
            }
            skipInstantSearchUiStateUpdate = false;
        }
    },
});


// This keeps Autocomplete aware of state changes coming from routing
// and updates its query accordingly
window.addEventListener('popstate', () => {
    skipInstantSearchUiStateUpdate = true;
    setQuery(search.helper?.state.query || '');
});

$(function () {
    if ($('.ais-SearchBox-submit').length) {
        $('.ais-SearchBox-submit').prop('title', `${Translator.translate('algolia_search')}`);
    };

    // Close search input and shad when pressing "enter" button
    if (algolia_mobile) {
        $('.aa-Input').on('keydown', function(e) {
            // Enter button keyCode == 13
            if (e.keyCode == 13) {
                repositionAlgoElems('hide');

                $('#algolia_shade').removeClass('visible');
            } else {
                return;
            }
        });
    }

    original_search_term = $('.aa-Input').val();

    var timer;
    $(window).on('scroll', function () {
        // Prevent script from triggering too much and causing blank search results
        if (timer) {
            window.clearTimeout(timer);
        }

        timer = window.setTimeout(function () {
            // If button is in viewport, trigger click (next results page load)
            if (isInViewport(document.querySelector('.ais-InfiniteHits-loadMore'))) {
                isFromScroll = true;
                var elementExists = document.querySelector(".ais-InfiniteHits-loadMore");
                if (elementExists) {
                    $(".ais-InfiniteHits-loadMore").addClass('loading');
                    document.querySelector(".ais-InfiniteHits-loadMore").click();
                }
            } else {
                if (isFromScroll) {
                    // If true was set on variable, don't forget to pass it to false once again
                    isFromScroll = false;
                }
            }
        }, 300);
    });

    $(window).on('load', function () {

        const customRegEx = /\?q=/;

        if (customRegEx.test(window.location.href)) {
            displayAlgoResults(alg_app_container, 'load');
        }
    });


    if (algolia_mobile) {
        $('#algolia_shade').on('click', function (event) {
            getSearchFocus(event);
        });

        var instantSearchElement = document.querySelector('.ais-InstantSearch');
        var statsElement = document.querySelector('.ais-InstantSearch #stats');

        instantSearchElement.addEventListener('scroll', function () {
            var scrollHeight = instantSearchElement.scrollHeight;
            var scrollTop = instantSearchElement.scrollTop;
            var elementHeight = statsElement.offsetHeight;
            var pageBody = document.body;

            if (scrollTop > elementHeight) {
                if (!pageBody.classList.contains('compactSearch')) {
                    pageBody.classList.add('compactSearch');
                }
            } else {
                if (pageBody.classList.contains('compactSearch')) {
                    pageBody.classList.remove('compactSearch');
                }
            }
        });
    } else {
        // display backToTopBtnAlgolia depending of scroll
        var backToTopBtnAlgolia = $('#backToTopAlgolia');

        $(window).scroll(function () {
            if ($(window).scrollTop() > 300) {
                backToTopBtnAlgolia.addClass('show');
            } else {
                backToTopBtnAlgolia.removeClass('show');
            }
        });
    }

});


function triggerAlgoInputShad(action) {
    const shade = document.getElementById('algolia_shade');
    if (action === 'add') {
        if (!shade.classList.contains('displayed')) {
            shade.classList.add('displayed');
        }
    } else {
        if (shade.classList.contains('displayed')) {
            shade.classList.remove('displayed');
        }
    }
}

function displayAlgoResults(container, action) {

    if (action == 'load') {
        if (!container.classList.contains('displayed')) {
            container.classList.add('displayed');
        }
    } else if (action == 'add') {
        if ($('.aa-Panel').is(':visible')) {
            $('.aa-Panel').hide();
            if (!container.classList.contains('displayed')) {
                container.classList.add('displayed');
            }
        }
    }

}

/* Handle query when typing in input and clicking a suggestion */
function initAutcompleteFromSuggestions(query) {
    let inputValue = document.querySelector('.aa-Input');
    $('.aa-Input').val(query);
    let event = document.createEvent('Event');
    event.initEvent('input', true, true);
    inputValue.dispatchEvent(event);

}

/*
*   closeFilters - Used to close rollover filters section
*
**/
function closeFilters() {
    $('.ais-InstantSearch #filter_sticky_search, #algolia_shade').removeClass('displayed');

    if ($('.ais-Panel.body_visible').length) {
        $('.ais-Panel.body_visible .selected_options').slideUp(200);
        $('.ais-Panel.body_visible').removeClass('body_visible');
    }
}

/*
*   toggleDropdown(event) - Used to display matching filters section (depending on target)
*   event => object (event)
**/
function toggleDropdown(event, target = null) {
    let panel = $(event.target).parents('.ais-Panel'),
        panel_body = panel.find('.ais-Panel-body');
    let filter_id = $(event.target).attr('id');

    if (panel_body.length) {
        if (!panel_body.is(':visible')) {

            if ($('.ais-Panel.body_visible').length) {
                $('.ais-Panel.body_visible .ais-Panel-body').slideUp(200);
                $('.ais-Panel.body_visible').removeClass('body_visible');
            }
            panel_body.slideDown(200);
            panel.addClass('body_visible');
        } else {
            panel_body.slideUp(200);
            panel.removeClass('body_visible');
        }
    }
}

/*
*   displayFilters(section) - Used to display rollover filters section
*   section => string
**/
function displayFilters(section) {
    if (section != 'undefined') {
        // Be sure you're not toggling same element back and fourth in order to keep clean display
        if (!$('.ais-InstantSearch #filter_sticky_search').find('#' + section).parents('.ais-Panel').hasClass('body_visible')) {
            $('.ais-InstantSearch #filter_sticky_search').find('#' + section).trigger('click');
        }
        // toggleDropdown
    }
    let shad = $('#algolia_shade');
    if ($('.ais-InstantSearch #filter_sticky_search').hasClass('displayed')) {
        $('.ais-InstantSearch #filter_sticky_search').removeClass('displayed');
        if (shad.hasClass('displayed')) {
            shad.removeClass('displayed');
        }
    } else {
        $('.ais-InstantSearch #filter_sticky_search, #algolia_shade').addClass('displayed');
    }

    shad.on("click touch", function () {
        closeFilters();
    });
}


/*
*   strNoAccent(a) - Function used to remove all accents from attributes name
*   a => string
**/
function strNoAccent(a) {
    var b = "áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ",
        c = "aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY",
        d = "";
    for (var i = 0, j = a.length; i < j; i++) {
        var e = a.substr(i, 1);
        d += (b.indexOf(e) !== -1) ? c.substr(b.indexOf(e), 1) : e;
    }
    return d;
}


/*
*   attrNicename(attribute) - Used to define an id on elements in order to toggle proper dropdown section in rollover filters section
*   attribute => string
**/
function attrNicename(attribute) {
    var str = transformAttribute(attribute);
    // Without accents, without spaces, without '
    str = strNoAccent(str.toLowerCase().replace('\'', '-').replace(/\s+/g, '-'));
    return str;
}


/*
*   transformAttribute(attribute) - Attribute transform in order to display in template
*   attribute => any (must be a string to work properly)
**/

function transformAttribute(attribute) {

    // We check if attr is string and it includes a "." to split on
    if (typeof attribute == 'string') {
        if (attribute.includes(".")) {
            let tmp_attr = attribute.split('.');

            // Check if split has generated an object
            let attr_nicename;
            if ((typeof tmp_attr == 'object') && (tmp_attr.length > 1)) {
                attr_nicename = tmp_attr[1];
            } else {
                attr_nicename = tmp_attr[0];
            }
            // Capitalize
            return attr_nicename.toLowerCase().charAt(0).toUpperCase() + attr_nicename.slice(1);

        } else {

            // if not, return capitalized raw attr
            return attribute.toLowerCase().charAt(0).toUpperCase() + attribute.slice(1);
        }
    } else if (typeof attribute != 'undefined') {
        // if not, return raw attr
        return attribute;
    }
}

/*
*   getPartPrice(price, part) - Used to split raw price in cents and decimal and returning specific part depending on "part"
*   price => number
*   part => string
**/
function getPartPrice(price, part) {

    if (typeof price == 'number') {
        let price_string = price.toString();
        if (price_string.includes(".")) {
            let tmp_price = price_string.split('.');
            // let tmp_price = substring(0, price_string.indexOf("."));
            decimal = tmp_price[0];
            cents = tmp_price[1];
        } else {
            decimal = price_string;
            cents = '00';
        }
    }

    return (part == 'cents') ? cents : decimal;
}

/*
*   calculateRating(rating) - Used to return string containing html for rating stars
*   rating => string
**/
function calculateRating(rating) {
    let rating_arr = rating.split('.'),
        rating1 = Number(rating_arr[0]),
        rating2 = Number(rating_arr[1]);
    let rateHasFloat = false;

    let rating_template = ``;


    if (rating > 0) {
        rating_template += `<div class="item_ratings"><div class="netreviews_reviews_rate" data-rate="4.666" data-percent="${(rating / 5) * 100}">`;

        for (let i = 1; i <= rating1; i++) {
            rating_template += `<span class="star_full"></span>`;
        }

        if (rating2 >= 3 && rating2 <= 7) {
            rating_template += `<span class="star_mid_full"></span>`;
            rateHasFloat = true;
        } else if (rating2 !== 0 && rating2 < 3) {
            rating_template += `<span class="star_empty"></span>`;
            rateHasFloat = true;
        } else if (rating2 !== 0 && rating2 > 7) {
            rating_template += `<span class="star_full"></span>`;
            rateHasFloat = true;
        }

        for (let i = 1; i <= (5 - (rateHasFloat ? (rating1 + 1) : (rating1))); i++) {
            rating_template += `<span class="star_empty"></span>`;
        }

        rating_template += `</div>`;

        rating_template += ` ${Math.round(rating, 2)}/5</div>`;
    } else {
        return '';
    }

    return rating_template;
}


/*
*   itemTemplate(rating) - Used to return string containing html for rating wshop items
*   rating => string
*   isNoResult => boolean
**/
function itemTemplateMustache(rating, isNoResult = false) {
    return `
    <div id="itm-{{product_id}}" class="item" data-id="itm-{{product_id}}"
    data-insights-object-id="{{objectID}}"
    data-insights-position="{{__position}}"
    data-insights-query-id="{{__queryID}}"
    >
        <div id="addToWishlistButtonContainer">
            <a href="javascript:;" id="addToWishlistButton" data-productid="{{product_id}}"
            data-idelt="{{product_id}}"
            class="btnWishlist bgSprite bfr wishlist"
            onclick="addToWishlist.call(this, '${is_user_connected}');"></a>
        </div>
        <div class="productSlider w-slider-container">
            <div class="algo-wrapper w-slider-wrapper ${(!all_visual_rayon && !algolia_mobile) ? 'rollover' : ''}">
                ${(all_visual_rayon || algolia_mobile) ? `{{#images}}
                    <div class ="w-slider-slide">
                        <a class="block_lnk" href="{{url}}&queryID={{__queryID}}">
                            <img src="{{.}}" class="imgProd" alt="{{name}}"/>
                        </a>
                    </div>
                    {{/images}}` : ''}
                ${(!algolia_mobile) ? `
                            <a class="block_lnk" href="{{url}}&queryID={{__queryID}}">
                            {{#images}}
                                <img src="{{.}}" class="imgProd" alt="{{name}}"/>
                            {{/images}}
                            </a>
                            ` : ''}
            </div>
            ${(all_visual_rayon || algolia_mobile) ? `<div class="btn next" onclick="scrollToNextPage('itm-{{product_id}}')"></div><div class="btn prev" onclick="scrollToPrevPage('itm-{{product_id}}')"></div>` : ''}

            {{#eclat_rond}}
                <div class="eclat_rond"><img src="{{eclat_rond}}" alt=" " /></div>
            {{/eclat_rond}}
            {{#eclat_rect}}
                <div class="eclat_rect"><img src="{{eclat_rect}}" alt=" " /></div>
            {{/eclat_rect}}
        </div>

        <a href="{{url}}&queryID={{__queryID}}" class="wrapper_description">
            <div class="hit-name item_title">
                <h3 class="item_title">{{title}}</h3>
                <span class="item_subtitle">{{subtitle}}</span>
            </div>

            ${!isNoResult ? `
            {{#produit_lot}}
                <div class="item_price">
                    {{#discounted_price_per_produit}}
                        <div class="hit-price pricetag is_discounted is_lot">{{discounted_price_per_produit_unit}}<span class="cent{{no_cents_class_discounted_per_produit}}">,{{discounted_price_per_produit_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                        <div class="hit-price pricetag crossed is_lot">{{price_per_produit_unit}}<span class="cents {{no_cents_class_per_produit}}">,{{price_per_produit_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price_per_produit}}
                    {{^discounted_price_per_produit}}
                        <div class="hit-price pricetag is_lot">{{price_per_produit_unit}}<span class="cents {{no_cents_class_per_produit}}">,{{price_per_produit_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price_per_produit}}
                    <div class="by_piece">${Translator.translate('par_piece')}</div>
                </div>
            {{/produit_lot}}
            {{^produit_lot}}
                <div class="item_price">
                    {{#discounted_price}}
                        <div class="hit-price pricetag is_discounted">{{discounted_price_unit}}<span class="cents {{no_cents_class_discounted}}">,{{discounted_price_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                        <div class="hit-price pricetag crossed">{{price_unit}}<span class="cents {{no_cents_class}}">,{{price_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price}}
                    {{^discounted_price}}
                        <div class="hit-price pricetag">{{price_unit}}<span class="cents {{no_cents_class}}">,{{price_cent}}</span> <span class="currency">${currencySymbol}</span></div>
                    {{/discounted_price}}
                </div>
            {{/produit_lot}}` : ``}

            ${calculateRating(rating)}
        </a>
        ${!isNoResult ? `
        <div class="wrap_rolloverproduit">
            <form id="prod_info_{{product_id}}" onsubmit="return false">
                <input id="produit_id_{{product_id}}" type="hidden" name="produit_id" value="{{product_id}}">
                <input id="suff_{{product_id}}" type="hidden" name="suff" value="{{product_id}}">
                <input id="produit_ref_{{product_id}}" type="hidden" name="produit_ref" value="{{produit_ref}}">
                <input id="produit_refext_{{product_id}}" type="hidden" name="produit_refext" value="{{external_reference}}">
                <input id="produit_principal_{{product_id}}" type="hidden" name="produit_principal" value="{{product_id}}">
                <input id="produit_lot_nb_{{product_id}}" type="hidden" name="produit_lot_nb" value="{{produit_lot_nb}}">
                <input id="titreObjet_{{product_id}}" type="hidden" name="titreObjet" value="{{title}}">
                <input id="idTaille_{{product_id}}" type="hidden" name="idTaille" value="tailleProd_{{size_id}}">
                <input id="idCouleur_{{product_id}}" type="hidden" name="idCouleur" value="couleurProd_{{color_id}}">
                <input id="path_web_{{product_id}}" type="hidden" name="path_web" value="${path_relative_root}">
                {{#produit_lot}}
                    {{#discounted_price_per_produit}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{discounted_price_per_produit}}">
                    {{/discounted_price_per_produit}}
                    {{^discounted_price_per_produit}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{price_per_produit}}">
                    {{/discounted_price_per_produit}}
                {{/produit_lot}}
                {{^produit_lot}}
                    {{#discounted_price}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{discounted_price}}">
                    {{/discounted_price}}
                    {{^discounted_price}}
                        <input id="prixU_{{product_id}}" type="hidden" name="prixU" value="{{price}}">
                    {{/discounted_price}}
                {{/produit_lot}}

                <div class="rollover_left achat_express">
                    <div id="ligne_couleur_{{product_id}}" class="ligne_form productColorFieldset">
                        <div class="prod_listes right_element_prod">
                            <div class="titre_choices_list">${Translator.translate('choose_color')}</div>
                            <div class="choices_list">
                                <a href={{url}} data-color="{{color}}" class="form_itm check color">
                                    <input type="hidden" name="itm_id_{{color_id}}" value="{{product_id}}">
                                    <input id="color_{{product_id}}_{{color_id}}" type="radio" name="itm_color" value="{{color_id}}" data-pic="" data-nom="{{color}}" disabled="disabled" class="input_check radio prodColor color_{{product_id}} color" checked>
                                    <span data-hex="{{color_code_hex}}" for="color_{{product_id}}_{{color_id}}" title="{{color}}"></span>
                                </a>
                                <input id="couleurProd_{{product_id}}" type="hidden" name="couleurProd" value="{{color_id}}" data-produitid="{{product_id}}">
                            </div>
                        </div>
                    </div>
                    <div id="ligne_pointure_{{product_id}}" class="ligne_form productSizeFieldset fromExpressV2">
                        <div id="size_list_{{product_id}}" class="prod_listes size_list right_element_prod">
                            <div class="titre_choices_list">${Translator.translate('choose_size')}</div>
                            <div class="choices_list">
                                <div class="form_itm check size">
                                    <input id="size_{{product_id}}_{{size_id}}" type="hidden" name="itm_size" value="{{size_id}}" data-pic="" data-nom="{{size}}" class="input_check radio prodSize size">
                                    <label for="size_{{product_id}}_{{size_id}}" title="{{size}}">{{size}}</label>
                                </div>
                            </div>
                        </div>
                        <input id="tailleProd_{{product_id}}" type="hidden" name="tailleProd" value="{{size_id}}" data-produitid="{{product_id}}">
                    </div>
                    <input id="qteProd_{{product_id}}" type="hidden" name="qteProd" value="1">
                </div>

                <div id="bloc_add_alert_{{product_id}}"
                    class="full btnAddBasketWrapper expressAlertStock bloc_add_alert_{{product_id}}"
                    style="display:none">
                    <div class="alert_stock">
                        <span>${Translator.translate('receive_alert')}</span>
                    </div>

                    <div class="bloc_add_alert_form">
                        <div class="w-form-line">
                            <div class="w-input-container">
                                <label class="w-input w-text-input">
                                    <input id="mail_alerte_stock_{{product_id}}"
                                        class="w-input-element" type="text" name="mail_alerte_stock"
                                        value="${user_mail}"
                                        placeholder="${Translator.translate('alert_stock_placeholder')}"/>
                                </label>
                            </div>
                            <div class="form_submit">
                                <button id="send_mail_alert_stock_{{product_id}}" type="button"
                                        onclick="sendMailAlertStock('stock', this.form, '')">
                                    <span>${Translator.translate('send_alert_stock')}</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="bloc_add_alert_confirmation" style="display:none">
                        <p>${Translator.translate('txt_confirmation_alerte_mail')}</p>
                    </div>

                    <div class="bloc_add_alert_erreur" style="display:none">
                        <p>${Translator.translate('txt_erreur_alerte_mail')}</p>

                        <button class="w-submit-button" onclick="lightboxAlertStock(this.form)">
                            <span>${Translator.translate('close')}</span>
                        </button>
                    </div>

                    <a class="alert_return"
                    onclick="closeAlerteStock('{{product_id}}')">
                        <span>X</span>
                    </a>
                </div>

                <div class="form_submit bloc_add_color wrapper_add_color_{{product_id}}">
                    <button id="bloc_add_color_{{product_id}}" type="button"
                            class="button dark w-submit-button" data-is-preco="<?php echo $is_preorder; ?>"
                            onclick="achatExpressV2.call(this, '{{product_id}}', '');">
                        <span>${Translator.translate('achat_express_v2')}</span>
                    </button>

                    <button class="button loader">
                        <span>${Translator.translate('loading')}</span>
                    </button>
                </div>
            </form>
        </div>` : ``}
    </div>`;
}

function itemTemplate(hit, rating, isNoResult = false) {
    // Use loops before retuning the string for array elements
    let images_template = ``;
    
    if (hit.images.length) {
        // Start images string by its container
        if (algolia_mobile) {
            if (!isNoResult) {
                images_template +=  `<div class="searchItemSlider swiper-container wrapper_img" onClick="StoreScrollPosition()"><div class="swiper-wrapper">`;
            }
        } else {
            images_template += `<div class="rolloverimgContainer"><a class="block_lnk" href="${hit.url}&queryID=${hit.__queryID}" onClick="StoreScrollPosition()">`;
        }

        // Loop to add all images inside the container
        for (let i = 0; i < hit.images.length; i++) {
            if (algolia_mobile) {
                // Mobile template
                if (!isNoResult) {
                    // Swipers if classic search results
                    images_template +=
                    `<div class="swiper-slide">
                        <img src="${hit.images[i]}" class="swiper-no-lazy" alt="${escapeHtml(hit.title)}"/>
                    </div>` ;
                }
            } else {
                images_template +=
                // `<img src="${hit.images[i]}" class="imgProd itm_vis ${i === hit.images.length - 1 && hit.images.length > 1 ? 'rollover_picture' : ''}" alt="${removeHTMLAndScripts(hit.title)}"/>`;

                `<span data-src="${hit.images[i]}" class="ill_img imgProd itm_vis ${i === hit.images.length - 1 && hit.images.length > 1 ? 'rollover_picture' : ''}" alt="${removeHTMLAndScripts(hit.title)}"></span>`;
            }
        }

        // Exception for mobile no results page
        if (algolia_mobile) {
            if (isNoResult) {
                images_template += `<div class="photo_product">
                    <div class="wrapper_img">
                        <img src="${hit["image-url"]}" alt="${hit.title}" />
                        ${hit.eclat_stock ?
                            `<div class="eclat_product"><img src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt="" /></div>`
                            :
                            `${hit.eclat_rond ?
                                `<div class="eclat_product"><img src="${path_relative_root + hit.eclat_rond}" alt="" /></div>`
                                :
                                ''
                            }`
                        }
                    </div>`
            }
        }

        // Close container and add eclats logic
        if (algolia_mobile) {
            if (!isNoResult) {
                images_template +=
                `</div></div>${hit.eclat_stock ?
                    `<div class="eclat_product"><img src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt="" /></div>`
                    :
                    `${hit.eclat_rond ?
                        `<div class="eclat_product"><img src="${path_relative_root + hit.eclat_rond}" alt="" /></div>`
                        :
                        ''
                    }`
                }`;
            }
        } else {
            images_template += `</a></div>`;
        }
    }

    if (algolia_mobile) {
        return `
        <div id="i${hit.product_id}" class="item item_container" data-prod="itm-${hit.product_id}"
            data-insights-object-id="${hit.objectID}" data-insights-position="${hit.__position}"
            data-insights-query-id="${hit.__queryID}">
            <div class="photo_product">
                <a href="${hit.url}&queryID=${hit.__queryID}" data-ec-type="product" data-ref="${hit.produit_ref}" class="item_link">
                    ${images_template}
                </a>
                <a 
                    href="javascript:;"
                    id="addToWishlistButton_${hit.product_id}"
                    data-productid="${hit.product_id}"
                    data-idelt="${hit.product_id}"
                    class="wishlistBtn addToWishlistButton"
                    onclick="addToWishlistRay.call(this, '${is_user_connected}');return false;"
                >
                </a>
            </div>

            <a href="${hit.url}&queryID=${hit.__queryID}" data-ec-type="product" data-ref="${hit.produit_ref}"
                class="item_link">
                <div class="item_btm">
                    <div class="wrap_txt">
                        <div class="title">
                            <span id="productTitle" class="product_title">${hit.title}</span>
                            <span class="separator"></span>
                            <span id="brandTitle">${hit.brand}</span>
                            <span class="subTitleRayon">${hit.subtitle}</span>
                        </div>
                    </div>
                    ${!isNoResult ? `
                    ${hit.produit_lot ?
                    `<div class="wrap_price">
                        ${hit.discounted_price_per_produit ?
                        `<p class="hit-price price_tag is_discounted">${hit.discounted_price_per_produit_unit}.<span class="cents">${hit.discounted_price_per_produit_cent}</span><span class="currency">${currencySymbol}</span></p>
                        <p class="hit-price price_tag crossed">${hit.price_per_produit_unit}.<span class="cent">${hit.price_per_produit_cent}</span><span class="currency">${currencySymbol}</span></p>`
                        :
                        `<p class="hit-price price_tag">${hit.price_per_produit_unit}.<span class="cents number_cent">${hit.price_per_produit_cent}</span><span class="currency">${currencySymbol}</span></p>`
                        }
                    </div>`
                    :
                    `<div class="wrap_price">
                        ${hit.discounted_price ?
                        `
                        <p class="hit-price price_tag is_lot is_discounted">${hit.discounted_price_unit}.<span class="cents number_cent ${hit.no_cents_class_discounted}">${hit.discounted_price_cent}</span><span class="currency">${currencySymbol}</span></p>
                        <p class="hit-price price_tag is_lot crossed">${hit.price_unit}.<span class="cents number_cent ${hit.no_cents_class}">${hit.price_cent}</span><span class="currency">${currencySymbol}</span></p>`
                        :
                        `<p class="hit-price price_tag is_lot">${hit.price_unit}.<span class="cents number_cent ${hit.no_cents_class}">${hit.price_cent}</span><span class="currency">${currencySymbol}</span></p>`
                        }
                    </div>`
                    }`
                    :
                    ``
                    }

                    <input id="tailleProd_${hit.product_id}" type="hidden" name="tailleProd" value="${hit.size_id}">
                    <input id="couleurProd_${hit.product_id}" type="hidden" name="couleurProd" value="${hit.color_id}">
                </div>
            </a>
        </div>`;
    } else {
        return `
        <div id="itm-${hit.product_id}" class="item wrapperProdVertical notPush" data-id="itm-${hit.product_id}"
            data-insights-object-id="${hit.objectID}" data-insights-position="${hit.__position}"
            data-insights-query-id="${hit.__queryID}">
            <div class="imgWrapper">
                <div class="container_eclats">
                    ${hit.eclat_stock && hit.eclat_stock.includes('epuise') ?
                    `
                    <img class="eclat_epuise eclat_spe"
                        src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt=" " />
                    `
                    :
                    `${hit.eclat_stock && hit.eclat_stock.includes('dispo') ?
                    `
                    <img class="eclat_nouveaute eclat_spe"
                        src="${path_relative_root + 'img/eclat/' + lang + '/' + hit.eclat_stock}" alt=" " />

                    `
                    :
                    ``
                    }
                    `}
                    ${hit.eclat_rect ?
                    `<img class="eclat_img eclat_img_1" src="${path_relative_root + hit.eclat_rect}" alt=" " />`
                    :
                    ''
                    }
                    ${hit.eclat_rond ?
                    `
                    <img class="eclat_img eclat_img_2" src="${path_relative_root + hit.eclat_rond}" alt=" " />
                    `
                    :
                    ''
                    }

                </div>
                ${images_template}

                <div class="wrap_rolloverproduit">
                    <div class="wrap_rolloverproduit_container">
                        <form id="prod_info_${hit.product_id}" onsubmit="return false">
                            <input id="produit_id_${hit.product_id}" type="hidden" name="produit_id" value="${hit.product_id}">
                            <input id="suff_${hit.product_id}" type="hidden" name="suff" value="${hit.product_id}">
                            <input id="produit_ref_${hit.product_id}" type="hidden" name="produit_ref"
                                value="${hit.produit_ref}">
                            <input id="produit_refext_${hit.product_id}" type="hidden" name="produit_refext"
                                value="${hit.external_reference}">
                            <input id="produit_principal_${hit.product_id}" type="hidden" name="produit_principal"
                                value="${hit.product_id}">
                            <input id="produit_lot_nb_${hit.product_id}" type="hidden" name="produit_lot_nb"
                                value="${hit.produit_lot_nb}">
                            <input id="titreObjet_${hit.product_id}" type="hidden" name="titreObjet" value="${hit.title}">
                            <input id="idTaille_${hit.product_id}" type="hidden" name="idTaille"
                                value="tailleProd_${hit.size_id}">
                            <input id="idCouleur_${hit.product_id}" type="hidden" name="idCouleur"
                                value="couleurProd_${hit.color_id}">
                            <input id="path_web_${hit.product_id}" type="hidden" name="path_web" value="${path_relative_root}">
                            ${hit.produit_lot ?
                            `${hit.discounted_price_per_produit ?
                            `<input id="prixU_${hit.product_id}" type="hidden" name="prixU"
                                value="${hit.discounted_price_per_produit}">`
                            :
                            `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${price_per_produit}">`
                            }`
                            :
                            `${hit.discounted_price ?
                            `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${hit.discounted_price}">`
                            :
                            `<input id="prixU_${hit.product_id}" type="hidden" name="prixU" value="${hit.price}">`
                            }`
                            }

                            <div class="rollover_left achat_express">
                                <a href="javascript:;" id="addToWishlistButton_${hit.product_id}"
                                    data-productid="${hit.product_id}" data-idelt="${hit.product_id}"
                                    class="wishlistBtn addToWishlistButton"
                                    onclick="addToWishlistRay.call(this, '${is_user_connected}');">
                                </a>
                                <input id="couleurProd_${hit.product_id}" type="hidden" name="couleurProd"
                                    value="${hit.color_id}" data-produitid="${hit.product_id}">
                                <input id="tailleProd_${hit.product_id}" type="hidden" name="tailleProd" value="${hit.size_id}"
                                    data-produitid="${hit.product_id}">
                                <input id="qteProd_${hit.product_id}" type="hidden" name="qteProd" value="1">
                            </div>

                            <div id="bloc_add_alert_${hit.product_id}"
                                class="full btnAddBasketWrapper expressAlertStock bloc_add_alert_${hit.product_id}"
                                style="display:none">
                                <div class="alert_stock">
                                    <span>${Translator.translate('receive_alert')}</span>
                                </div>

                                <div class="bloc_add_alert_form">
                                    <div class="w-form-line">
                                        <div class="w-input-container">
                                            <label class="w-input w-text-input">
                                                <input id="mail_alerte_stock_${hit.product_id}" class="w-input-element"
                                                    type="text" name="mail_alerte_stock" value="${user_mail}"
                                                    placeholder="${Translator.translate('alert_stock_placeholder')}" />
                                            </label>
                                        </div>
                                        <div class="form_submit">
                                            <button id="send_mail_alert_stock_${hit.product_id}" type="button"
                                                onclick="sendMailAlertStock('stock', this.form, '')">
                                                <span>${Translator.translate('send_alert_stock')}</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div class="bloc_add_alert_confirmation" style="display:none">
                                    <p>${Translator.translate('txt_confirmation_alerte_mail')}</p>
                                </div>

                                <div class="bloc_add_alert_erreur" style="display:none">
                                    <p>${Translator.translate('txt_erreur_alerte_mail')}</p>

                                    <button class="w-submit-button" onclick="lightboxAlertStock(this.form)">
                                        <span>${Translator.translate('close')}</span>
                                    </button>
                                </div>

                                <a class="alert_return" onclick="closeAlerteStock('${hit.product_id}')">
                                    <span>X</span>
                                </a>
                            </div>

                            ${!hit.eclat_stock ?
                            `<div class="form_submit bloc_add_color wrapper_add_color_${hit.product_id}">
                                <button id="bloc_add_color_${hit.product_id}" type="button" class="button dark w-submit-button"
                                    onclick="achatExpressV2.call(this, '${hit.product_id}', '');">
                                    <span>${Translator.translate('achat_express_v2')}</span>
                                </button>

                                <button class="button loader">
                                    <span>${Translator.translate('loading')}</span>
                                </button>
                            </div>`
                            :
                            ``
                            }

                            ${(hit.eclat_stock && !hit.eclat_stock.includes('epuise')) ?
                            `<div class="form_submit bloc_add_color no_stock wrapper_add_color_${hit.product_id}"><button
                                    id="bloc_add_color_${hit.product_id}" type="button" data-is-preco=""
                                    onclick="algoliaAlertStock($(this), '${hit.product_id}', '');"
                                    class="button dark w-submit-button out_stock"><span>${Translator.translate('receive_alert')}</span></button>
                                <button class="button loader"><span>loading</span></button></div>`
                            :
                            ''
                            }
                        </form>
                    </div>
                </div>
            </div>
            <a href="${hit.url}&queryID=${hit.__queryID}" class="block_lnk">
                <div class="wrapper_description">
                    <h3 class="item_name">
                        <span>${hit.title}</span>
                        <span class="item_subtitle">${hit.subtitle}</span>
                    </h3>
                    <div class="wrapper_price">
                        ${hit.discounted_price ?
                        `<div class="hit-price pricetag new-price">${hit.discounted_price_unit}.<span class="cents number_cent ${hit.no_cents_class_discounted}">${hit.discounted_price_cent}</span><span class="currency">${currencySymbol}</span></div>
                        <div class="hit-price pricetag crossed">${hit.price_unit}.<span class="cents number_cent ${hit.no_cents_class}">${hit.price_cent}</span><span class="currency">${currencySymbol}</span></div>`
                        :
                        `<div class="hit-price pricetag">${hit.price_unit}.<span class="cents number_cent ${hit.no_cents_class}">${hit.price_cent}</span><span class="currency">${currencySymbol}</span></div>`
                        }
                    </div>
                </div>
            </a>


        </div>`;
    }
}

/**
 * Init algolia search
 */
function initAlgolia() {

    var algoliaSearchTop = document.querySelector('.algoliaSearchTop');
    var algoliaSearch = document.querySelector('.ais-container');
    var algoliaSearchInput = document.querySelector('.aa-Input');
    var algoliaResults = document.querySelector('.ais-InstantSearch');

    algoliaSearch.classList.add('inUse');
    algoliaSearchInput.focus();
}

/**
 * Reset Algolia search
 */
function resetAlgolia() {
    var algoliaSearchInput = $('.aa-Input');

    setTimeout(function () {
        document.querySelector('.ais-container').classList.remove('inUse');
        document.querySelector('.ais-InstantSearch').classList.remove('displayed');
        algoliaSearchInput.focusout();
        triggerAlgoInputShad('remove');
    }, 200);
}

/**
 * function initializeSwiper
 *
 *
 */
function initializeSwiper(container, params) {
    new Swiper(container, params);
}

/**
 * function initializeSuggSwiper
 *
 *
 */
function initializeSuggSwiper(container, params) {
    new Swiper(container, params);
}

function itemAssociationsTemplate(hit) {
    let colors = hit.associated_products;
    let string_associations = ``;

    if (colors.length) {
        // Product has more than one color
        if (colors.length > 1) {
            for (let i = 0; i < ((max_color_display !== undefined) ? (max_color_display - 1) : colors.length); i++) {
                if (i == 0 && colors[i].main_image_url.length) {
                    string_associations +=
                        `<a href="${hit.url}" data-color="${hit.color}" class="form_itm check color">
                        <input type="hidden" name="itm_id_${hit.product_id}" value="${hit.product_id}">
                        <input id="color_${hit.product_id}_${hit.color_id}" type="radio" name="itm_color" value="${hit.color_id}" data-pic="" data-nom="${hit.color}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color" checked="checked">
                        <span data-hex="${hit.color_code}" for="color_${hit.product_id}_${hit.color_id}" title="${hit.color}" style="background-image: url(${colors[i].main_image_url});"></span>
                    </a>`;
                }
                string_associations +=
                    `<a href="${colors[i].url}" data-color="${colors[i].colors.couleur_nom}" class="form_itm check color">
                    <input type="hidden" name="itm_id_${colors[i].id}" value="${hit.product_id}">
                    <input id="color_${hit.product_id}_${colors[i].id}" type="radio" name="itm_color" value="${colors[i].id}" data-pic="" data-nom="${colors[i].colors.couleur_nom}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color">
                    <span data-hex="${colors[i].colors.couleur_code_hex}" for="color_${hit.product_id}_${colors[i].id}" title="${colors[i].colors.couleur_nom}" style="background-image: url(${colors[i].image_url});"></span>
                </a>`;
            }

            // If there are still colors existing but not displayed
            if ((colors.length - max_color_display) > 0) {
                string_associations +=
                    `<a href="${hit.url}" class="remaining_colors">+ ${colors.length - max_color_display}</a>`;
            }
        } else {
            string_associations +=
                `<a href="${hit.url}" data-color="${hit.color}" class="form_itm check color">
                <input type="hidden" name="itm_id_${hit.product_id}" value="${hit.product_id}">
                <input id="color_${hit.product_id}_${hit.color_id}" type="radio" name="itm_color" value="${hit.color_id}" data-pic="" data-nom="${hit.color}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color" checked="checked">
                <span data-hex="${hit.color_code}" for="color_${hit.product_id}_${hit.color_id}" title="${hit.color}" style="background-image: url(${colors[0].main_image_url});"></span>
            </a>
            <a href="${colors[0].url}" data-color="${colors[0].colors.couleur_nom}" class="form_itm check color">
                <input type="hidden" name="itm_id_${colors[0].id}" value="${hit.product_id}">
                <input id="color_${hit.product_id}_${colors[0].id}" type="radio" name="itm_color" value="${colors[0].id}" data-pic="" data-nom="${colors[0].colors.couleur_nom}" disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color">
                <span data-hex="${colors[0].colors.couleur_code_hex}" for="color_${hit.product_id}_${colors[0].id}" title="${colors[0].colors.couleur_nom}" style="background-image: url(${colors[0].image_url});"></span>
            </a>`;
        }
    } else {
        string_associations +=
            `<a href="${hit.url}" data-color="${hit.color}" class="form_itm check color cache">
                <input type="hidden" name="itm_id_${hit.product_id}" value="${hit.product_id}">
                <input id="color_${hit.product_id}_${hit.color_id}" type="radio" name="itm_color" value="${hit.color_id}" data-pic="" data-nom="${hit.color} disabled="disabled" class="input_check radio prodColor color_${hit.product_id} color">
                <span data-hex="${hit.color_code}" for="color_${hit.product_id}_${hit.color_id}" title="${hit.color}"></span>
            </a>`;
    }

    return string_associations;
}

function itemAssociationsTemplateMobile(hit) {
    let colors = hit.associated_products;
    let string_associations = ``;

    if (colors.length) {
        string_associations += `<div class="item_available_colors">`;
        // Product has more than one color
        if (colors.length > 1) {
            for (let i = 0; i < ((max_color_display !== undefined) ? (max_color_display - 1) : colors.length); i++) {
                if (i == 0 && colors[i].main_image_url.length) {
                    string_associations +=
                        `<span class="item_color_bloc actif" style="background-image: url(${colors[0].main_image_url});">
                    </span>`;
                }
                string_associations +=
                    `<span class="item_color_bloc" style="background-image: url(${colors[i].image_url});">
                </span>`;
            }

            string_associations += `<input id="couleurProd_${hit.product_id}_${colors[0].id}" type="hidden" name="couleurProd" value="${colors[0].id}" />`;

            // If there are still colors existing but not displayed
            if ((colors.length - max_color_display) > 0) {
                string_associations +=
                    `<a href="${hit.url}" class="remaining_colors">+ ${colors.length - max_color_display}</a>`;
            }
        } else {
            string_associations +=
                `<span class="item_color_bloc actif" style="background-image: url(${colors[0].main_image_url});">
            </span>
            <span class="item_color_bloc" style="background-image: url(${colors[0].image_url});">
            </span>
            <input id="couleurProd_${hit.product_id}_${colors[0].id}" type="hidden" name="couleurProd" value="${colors[0].id}" />`;
        }
        string_associations += `</div>`;
    } else {
        string_associations +=
            `<input id="couleurProd_${hit.product_id}_${hit.color_id}" type="hidden" name="couleurProd" value="${hit.color_id}" />`;
    }
    return string_associations;
}


/** function decode : used to parse html special chars
 * str = string;
 *
 */
function decode(str) {
    let txt = new DOMParser().parseFromString(str, "text/html");
    return txt.documentElement.textContent;
}

/** function decode : used to parse html special chars
 * str = string;
 *
 */
function checkToggledFilter(filter) {
    let html_class = "";
    if (filter && (filter !== undefined)) {
        if ($(`#filter_sticky_search #${filter}`).parents('.filters_dropdown_bloc').hasClass('body_visible')) {
            html_class = "toggled";
        }
    }

    return html_class;
}

// Custom alert stock --> achatExpressV2 override only for Algolia
function algoliaAlertStock(currentElem, product_id, regroup_ref_id) {
    let elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;

    currentElem.parent().hide();
    $('#bloc_add_alert' + elem).show();
}

// La fonction isInViewport sert à contrôler si le bouton apparaît à l'écran. Si c'est le cas, elle retourne true et le clic est simulé, sinon non. Cette fonction est appelée dans la condition du clic lors du défilement.
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return (elementBottom > viewportTop) && (elementTop < viewportBottom);
}

/** function removeHTMLAndScripts :
 * query : type String
 */
function removeHTMLAndScripts(query) {
    // Replaces HTML characters into spaces
    let cleanInput = query.replace(/<[^>]*>/g, '');
    // Removes JavaScript scripts
    cleanInput = cleanInput.replace(/<script[^>]*>[\s\S]*?<\/script>/gi, '');
    // Removes risky codes
    cleanInput = cleanInput.replace(/[{}()]/g, '');
    // Removes special chars usually used on XSS attacks
    cleanInput = cleanInput.replace(/[&<>"'/]/g, function (match) {
        return {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#39;',
            "/": '&#x2F;'
        }[match];
    });
    return cleanInput;
}

/** function isStringCorrupted :
 * string : type String
 * Checks if string contains HTML or script characters
 */
function isStringCorrupted(string) {
    const htmlScriptRegex = /(<([^>]+)>|<script[^>]*>[\s\S]*?<\/script>)/ig;

    return htmlScriptRegex.test(string) ? true : false;
}

/** function StoreScrollPosition :
 * Store in local storage scroll position when user goes to product Pageview
 * Position  will used on return to algolia search page
 */
function StoreScrollPosition() {
    if (!algolia_mobile) {
        sessionStorage.setItem('scrollPosition', window.pageYOffset);
    } else {
        var el = document.querySelector('.ais-InstantSearch');
        var position = el.scrollTop;
        sessionStorage.setItem('scrollPosition', position);
    }
}

/** function setBackToTop :
* Sets scroll position back to top of page
*/
function setBackToTop() {
    // Check if scroll is not already at top
    if ($(window).scrollTop() > 0) {
        // const target = algolia_mobile ? ".ais-InstantSearch" : "html, body"
        const target = "html, body";
        $(target).animate({
            scrollTop: 0
        }, 200);
    }
}


// Algolia upadte swiper state when search suggestions are displayed
function swiperStateUpdate() {
    if($('#query_suggestions_list').length) {
        var hits = $('#query_suggestions_list .list_suggestions .suggestion');
        $('#query_suggestions_list').toggleClass('single_suggestion', hits.length < 2);
    }
}

function preferredOrder(obj, order) {
    var newObject = {};
    for(var i = 0; i < order.length; i++) {
        if(obj.hasOwnProperty(order[i])) {
            newObject[order[i]] = obj[order[i]];
        }
    }
    return newObject;
}

/* function escapeHTML --> htmlspecialchars() equivalent */
function escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"']/g, function(m) { return map[m]; });
}

/**
 * function initializeSwiper
 *
 * Used to init product sliders in category / search page
 */
function initItemSlider(isToggled) {
    if ($('#hits .prodItemSlider, #hits .searchItemSlider').length) {
        if (isToggled == true) { // Toggle view is triggerred
            setTimeout(function () {
                $(' #hits .prodItemSlider, .searchItemSlider').each(function () {
                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll
            $(".searchItemSlider").each(function () {
                if (isInViewport($(this).parents('.ais-InfiniteHits-item')[0]) && !$(this).hasClass('initialized')) {
                    new Swiper($(this)[0]);
                    $(this).addClass('initialized');
                }
            });
        }
    }
}